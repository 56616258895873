var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box__header"},[(
        _vm.accStatus !== _vm.$Consts('ACCREDITATION_STATUS_ACTIVE') &&
          _vm.accStatus !== _vm.$Consts('ACCREDITATION_STATUS_RENEWAL_IN_PROGRESS') &&
          _vm.accStatus !== _vm.$Consts('ACCREDITATION_STATUS_RENEWAL_SUBMITTED')
      )?_c('h2',{staticClass:"box__header-title box__header-title--green"},[_vm._v(" "+_vm._s(_vm.accreditationRenewBoxTitle('accRenewBoxTitle'))+" ")]):_c('h2',{staticClass:"box__header-title box__header-title--green"},[_vm._v(_vm._s(_vm.accreditationRenewBoxTitle('accRenewBoxTitleDone')))])]),_c('div',{staticClass:"box__body"},[(_vm.accStatus === _vm.$Consts('ACCREDITATION_STATUS_PENDING_RENEWAL') && !_vm.isDraft)?_c('button-gcr',{attrs:{"btnClass":"btn-primary btn-sm btn-arrow","label":"accRenewBoxBtnNew"},on:{"btnclick":function($event){return _vm.accreditationFormUrlClickNew()}}}):_vm._e(),(_vm.accStatus === _vm.$Consts('ACCREDITATION_STATUS_PENDING_RENEWAL') && _vm.isDraft)?_c('button-gcr',{attrs:{"btnClass":"btn-primary btn-sm btn-arrow","label":"accRenewBoxBtnEdit"},on:{"btnclick":function($event){return _vm.accreditationFormUrlClickNew()}}}):_vm._e(),(
        _vm.accStatus === _vm.$Consts('ACCREDITATION_STATUS_RENEWAL_IN_PROGRESS') ||
          _vm.accStatus === _vm.$Consts('ACCREDITATION_STATUS_RENEWAL_SUBMITTED')
      )?_c('p',[_c('strong',{staticClass:"text-warning"},[_vm._v(_vm._s(_vm.$t('accDetailStatusPendingInProgress')))])]):_vm._e(),(
        _vm.accStatus !== _vm.$Consts('ACCREDITATION_RENEWAL_STATUS_CLOSED') &&
          _vm.accStatus !== _vm.$Consts('ACCREDITATION_STATUS_PENDING_RENEWAL') &&
          _vm.accRenewBoxSubmitDate
      )?_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('accRenewBoxSubmitDate')))]),_vm._v(" "+_vm._s(this.accRenewBoxSubmitDate)+" ")]):_vm._e(),(_vm.accStatus === _vm.$Consts('ACCREDITATION_STATUS_PENDING_RENEWAL'))?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('accRenewBoxHTML'))}}):_vm._e(),(
        _vm.accStatus === _vm.$Consts('ACCREDITATION_STATUS_RENEWAL_SUBMITTED') ||
          _vm.accStatus === _vm.$Consts('ACCREDITATION_STATUS_RENEWAL_IN_PROGRESS')
      )?_c('p',[_vm._v(" "+_vm._s(_vm.$t('accRenewBoxInProgress'))+" ")]):_vm._e(),(this.accreditation.renewalFormDownloadUrl)?_c('p',[_c('a',{attrs:{"href":_vm.getDownloadUrl(this.accreditation.renewalFormDownloadUrl)}},[_vm._v(_vm._s(_vm.$t('accRenewBoxDownloadForm')))])]):_vm._e(),(
        (_vm.accStatus === _vm.$Consts('ACCREDITATION_STATUS_RENEWAL_SUBMITTED') ||
          _vm.accStatus === _vm.$Consts('ACCREDITATION_STATUS_ACTIVE')) &&
          this.accreditation.renewal &&
          this.accreditation.renewal.invoice &&
          this.accreditation.renewal.invoice.downloadUrl &&
          this.accreditation.renewal.invoice.balance <= 0
      )?_c('p',[_c('a',{attrs:{"href":_vm.getDownloadUrl(this.accreditation.renewal.invoice.downloadUrl)}},[_vm._v(_vm._s(_vm.$t('accRenewBoxInvoice')))])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }