<template>
  <div class="box">
    <div class="box__header box__header--icon box__header--icon-info-warning">
      <h2 class="box__header-title">
        {{ $t('missingUnitsDocumentsTitle') }}
        <small class="box__header-smallnote">{{ $t('missingUnitsDocumentsTitleEmailNote') }}</small>
      </h2>
    </div>
    <div class="box__body">
      <ul>
        <li v-for="item in accreditation.missingDocuments" :key="item">{{ item }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
require('../../utilities/box')

export default {
  props: { accreditation: Object }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';
.box__header--icon-exclamation:before {
  background-image: url(../../assets/error_circle_red.svg);
  background-color: gray('white'); //prevent icon see-through
  border-radius: 100px;
}
.box__header--icon-info-warning:before {
  background-image: url(../../assets/error_circle_orange.svg);
  background-color: gray('white'); //prevent icon see-through
  border-radius: 100px;
  transform: rotate(180deg);
}
</style>
