<template>
  <div class="page__body page__body--bg page__body--hastab page__body--nopadding2 row justify-content-center">
    <div class="page__body__image"></div>
    <div class="col-12 col-xs-11" v-if="accreditation.company">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="box">
            <div class="box__header">
              <h2 class="box__header-title">{{ $t('accDetailMyAccreditation') }}</h2>
            </div>
            <div class="box__body">
              <p>
                <strong>{{ $t('accDetailState') }} </strong>
                <strong class="text-warning" v-if="accreditationStatus === $Consts('ACCREDITATION_STATUS_PENDING_RENEWAL')">{{
                  $t('accDetailStatusPending')
                }}</strong>
                <strong
                  class="text-warning"
                  v-if="
                    accreditationStatus === $Consts('ACCREDITATION_STATUS_RENEWAL_IN_PROGRESS') ||
                      accreditationStatus === $Consts('ACCREDITATION_STATUS_RENEWAL_SUBMITTED')
                  "
                  >{{ $t('accDetailStatusPendingInProgress') }}</strong
                >
                <strong class="text-primary" v-if="accreditationStatus === $Consts('ACCREDITATION_STATUS_ACTIVE')">{{
                  $t('accDetailStatusActive')
                }}</strong>
                <strong class="text-danger" v-if="accreditationStatus === 'CLOSED'">{{
                  $t('accDetailStatusClosed')
                }}</strong>
                <br />
                <strong>{{ $t('accDetailNoAccreditation') }} </strong>{{ accreditation.number }}<br />
                <strong>{{ $t('accDetailNoRBQ') }} </strong> {{ accreditation.company.rbqLicenceNumber }}<br />
                <strong>{{ $t('accDetailAnalyst') }} </strong> <a v-bind:href="'mailto:' + email">{{ fullname }}</a>
              </p>
            </div>
            <div class="box__body" v-if="accreditationStatus != 'CLOSED'">
              <div class="o-accdetail__creastbox">
                <template
                  v-if="
                    renewalDays <= $Consts('RENEWALL_DAYS_LIMIT') &&
                      !isRenewalPast &&
                      accreditationStatus != $Consts('ACCREDITATION_STATUS_ACTIVE')
                  "
                >
                  <round-crest
                    v-if="
                      renewalDays <= $Consts('RENEWALL_DAYS_LIMIT') &&
                        accreditationStatus != $Consts('ACCREDITATION_STATUS_RENEWAL_IN_PROGRESS') &&
                        accreditationStatus != $Consts('ACCREDITATION_STATUS_RENEWAL_SUBMITTED')
                    "
                    :content="renewalDays"
                    :label="labelDay"
                    crestClass="a-crest--warning-bordered"
                  />
                  <div class="o-accdetail__creastbox__text">
                    <p
                      v-if="
                        accreditationStatus != $Consts('ACCREDITATION_STATUS_RENEWAL_IN_PROGRESS') &&
                          accreditationStatus != $Consts('ACCREDITATION_STATUS_RENEWAL_SUBMITTED')
                      "
                    >
                      {{ renewalDateText }}
                    </p>
                    <p v-else>{{ $t('renewalInStudyText') }}</p>
                    <p>
                      {{ $t('accDetailLimitPaymentDate') }} <strong>{{ renewalDate }}</strong>
                    </p>
                  </div>
                </template>

                <template v-else-if="isRenewalPast && accreditationStatus === $Consts('ACCREDITATION_STATUS_PENDING_RENEWAL')">
                  <round-crest :content="renewalDays" :label="labelDay" crestClass="a-crest--danger-bordered" />
                  <div class="o-accdetail__creastbox__text">
                    <p v-html="renewalIsPastDateAndPendingText"></p>
                    <p>{{ $t('YourFileCouldBeClosed') }}</p>
                    <p>
                      {{ $t('renewalDueOn') }} <strong>{{ renewalDate }}</strong>
                    </p>
                  </div>
                </template>

                <template v-else-if="isRenewalPast && accreditationStatus != $Consts('ACCREDITATION_STATUS_ACTIVE')">
                  <round-crest
                    v-if="
                      accreditationStatus != $Consts('ACCREDITATION_STATUS_RENEWAL_IN_PROGRESS') &&
                        accreditationStatus != $Consts('ACCREDITATION_STATUS_RENEWAL_SUBMITTED')
                    "
                    :content="renewalDays"
                    :label="labelDay"
                    crestClass="a-crest--danger-bordered"
                  />
                  <div
                    class="o-accdetail__creastbox__text"
                    v-if="
                      accreditationStatus != $Consts('ACCREDITATION_STATUS_RENEWAL_IN_PROGRESS') &&
                        accreditationStatus != $Consts('ACCREDITATION_STATUS_RENEWAL_SUBMITTED')
                    "
                  >
                    <p v-html="renewalIsPastDateText"></p>
                    <p>{{ $t('renewalDatePastButSubmitted') }}</p>
                    <p>
                      {{ $t('renewalDueOn') }} <strong>{{ renewalDate }}</strong>
                    </p>
                  </div>
                  <div v-else class="o-accdetail__creastbox__text">
                    <p>{{ $t('renewalInStudyText') }}</p>
                  </div>
                </template>

                <template v-else>
                  <p>{{ $t('yourNextRenewal') }}{{ renewalDate }}</p>
                </template>
              </div>
            </div>
            <div
              v-else-if="accreditationStatus === $Consts('ACCREDITATION_STATUS_PENDING_RENEWAL')"
              class="box__footer box__footer--info"
            >
              <p v-html="$t('accDetailRBQNoticeHTML')"></p>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="box">
            <div class="box__header o-accdetail__box-header-with-creast">
              <h2 class="box__header-title">{{ $t('accDetailRatingTitle') }} &nbsp;</h2>
              <round-crest
                :content="accreditation.globalRating"
                crestClass="a-crest--primary-bordered a-crest--sm o-accdetail__box-header-creast"
                v-show="accreditationStatus !== 'CLOSED'"
              />
              <round-crest
                :content="'-'"
                crestClass="a-crest--gray-bordered a-crest--sm o-accdetail__box-header-creast"
                v-show="accreditationStatus === 'CLOSED'"
              />
            </div>
            <div class="box__body">
              <div class="o-accdetail__ratings">
                <div class="row">
                  <div class="col-3 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                    <strong>{{ $t('accDetailTechnicalRating') }}</strong>
                  </div>
                  <div class="col-9 col-sm-6 col-md-7 col-lg-8 col-xl-9">
                    <span
                      class="o-accdetail__xs-creast"
                      :class="[accreditation.ratings.technical.value.length > 1 ? 'o-accdetail__xs-creast--doubledigit' : '']"
                      v-show="accreditationStatus !== 'CLOSED'"
                      >{{ accreditation.ratings.technical.value }}</span
                    >
                    <span
                      class="o-accdetail__xs-creast o-accdetail__xs-creast--gray"
                      v-show="accreditationStatus === 'CLOSED'"
                      >-</span
                    >
                    <span class="o-accdetail__rate"
                      >{{ Math.round(accreditation.ratings.technical.score * 10) / 10 }}/{{
                        accreditation.ratings.technical.total
                      }}</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                    <strong>{{ $t('accDetailFinancialRating') }}</strong>
                  </div>
                  <div class="col-9 col-sm-6 col-md-7 col-lg-8 col-xl-9">
                    <span
                      class="o-accdetail__xs-creast"
                      :class="[accreditation.ratings.financial.value.length > 1 ? 'o-accdetail__xs-creast--doubledigit' : '']"
                      v-show="accreditationStatus !== 'CLOSED'"
                      >{{ accreditation.ratings.financial.value }}</span
                    >
                    <span
                      class="o-accdetail__xs-creast o-accdetail__xs-creast--gray"
                      v-show="accreditationStatus === 'CLOSED'"
                      >-</span
                    >
                    <span class="o-accdetail__rate"
                      >{{ Math.round(accreditation.ratings.financial.score * 10) / 10 }}/{{
                        accreditation.ratings.financial.total
                      }}</span
                    >
                  </div>
                </div>
                <div class="row" v-if="accreditation.ratings.satisfaction.total !== 0">
                  <div class="col-3 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                    <strong>{{ $t('accDetailSatisfactionRating') }}</strong>
                  </div>
                  <div class="col-9 col-sm-6 col-md-7 col-lg-8 col-xl-9">
                    <span
                      class="o-accdetail__xs-creast"
                      :class="[accreditation.ratings.satisfaction.value.length > 1 ? 'o-accdetail__xs-creast--doubledigit' : '']"
                      v-show="accreditationStatus !== 'CLOSED'"
                      >{{ accreditation.ratings.satisfaction.value }}</span
                    >

                    <span
                      class="o-accdetail__xs-creast o-accdetail__xs-creast--gray"
                      v-show="accreditationStatus === 'CLOSED'"
                      >-</span
                    >

                    <span class="o-accdetail__rate"
                      >{{ Math.round(accreditation.ratings.satisfaction.score * 10) / 10 }}/{{
                        accreditation.ratings.satisfaction.total
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <p>
                {{ $t('accDetailTrainingBonus') }} {{ accreditation.ratings.training.score }} {{ $t('accDetailPoint')
                }}<span v-if="accreditation.ratings.training.score > 1">s</span>
                <a
                  href="https://www.garantiegcr.com/fr/actualites-gcr/mieux-former-les-entrepreneurs-pour-prevenir-des-defauts-de-construction/"
                  target="_blank"
                  class="o-accdetail__ratings-link"
                  >{{ $t('accDetailKnowMore') }}</a
                >
              </p>
              <p>
                <a href="https://www.garantiegcr.com/fr/entrepreneurs/cote-qualite-gcr/" target="_blank">{{
                  $t('accDetailHowRatingWork')
                }}</a>
              </p>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="box box--nomargin">
            <div class="box__header">
              <h2 class="box__header-title">{{ $t('accDetailFinancialStatements') }}</h2>
            </div>
            <div class="box__body">
              <p>{{ $t('accDetailFinancialStatementDeadline') }}</p>
              <p class="o-accdetail__reportDueDate" :class="isFinanceReportDueDateIsPast ? 'text-danger' : ''">
                <strong>{{ financeReportDueDate }}</strong>
              </p>
            </div>
            <div class="box__footer box__footer--info">
              <p>{{ $t('accDetailFinancialStatementsNotice1') }}</p>
              <p>{{ $t('accDetailFinancialStatementsNotice2') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoundCrest from '@/components/atoms/RoundCrest'
import LoadingWrapper from '@/components/organism/LoadingWrapper'
import { Dates, Consts } from '@/_helpers'
import dayjs from 'dayjs'

require('../../utilities/box')

export default {
  components: { RoundCrest, LoadingWrapper },
  props: { accreditation: Object },
  data() {
    return {
      accreditationStatus: this.accreditation.status,
      isAccreditationLoading: true
    }
  },
  computed: {
    renewalDateText() {
      if (
        this.accreditationStatus === Consts.ACCREDITATION_STATUS_RENEWAL_IN_PROGRESS ||
        this.accreditationStatus === Consts.ACCREDITATION_STATUS_RENEWAL_SUBMITTED
      ) {
        return this.renewalDays > 1
          ? this.$t('renewalDate').replace('$1', this.renewalDays)
          : this.$t('renewalDateSingle').replace('$1', this.renewalDays)
      } else {
        return this.renewalDays > 1
          ? this.$t('renewalDateInReview').replace('$1', this.renewalDays)
          : this.$t('renewalDateInReviewSingle').replace('$1', this.renewalDays)
      }
    },
    renewalDate() {
      return this.accreditation.renewalDate ? Dates.formatDate(this.accreditation.renewalDate) : ''
    },
    financeReportDueDate() {
      return this.accreditation.company.financeReportDueDate
        ? Dates.formatDate(this.accreditation.company.financeReportDueDate)
        : ''
    },
    isFinanceReportDueDateIsPast() {
      return new Date(this.accreditation.company.financeReportDueDate) < new Date()
    },
    renewalIsPastDateText() {
      return this.renewalDays > 1
        ? this.$t('renewalDatePast').replace('$1', this.renewalDays)
        : this.$t('renewalDatePastSingle').replace('$1', this.renewalDays)
    },
    renewalIsPastDateAndPendingText() {
      return this.renewalDays > 1
        ? this.$t('renewalDatePastAndPending').replace('$1', this.renewalDays)
        : this.$t('renewalDatePastAndPendingSingle').replace('$1', this.renewalDays)
    },
    isRenewalPast() {
      return this.accreditation.renewalDate && Dates.isPast(this.accreditation.renewalDate)
    },
    renewalDays() {
      let renewalDate = dayjs(this.accreditation.renewalDate)
      let now = dayjs()
      let diff

      if (this.isRenewalPast) {
        diff = now.diff(renewalDate, 'day')
      } else {
        diff = renewalDate.add(1, 'day').diff(now, 'day')
      }

      return diff
    },
    labelDay() {
      return this.renewalDays > 1 ? this.$t('days') : this.$t('day')
    },
    fullname() {
      return `${this.accreditation.analyst.firstName} ${this.accreditation.analyst.lastName}`
    },
    email() {
      return this.accreditation.analyst.emails.length > 0 ? this.accreditation.analyst.emails[0].address : ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';
.o-accdetail {
  &__reportDueDate {
    font-size: 1.35em;

    &--ispast {
      color: theme-color('warning');
    }
  }

  &__creastbox {
    display: flex;
    align-items: center;
    & > :first-child {
      margin-top: 0;
    }
    & > :last-child {
      margin-bottom: 0;
    }
    &__text {
      &:first-child {
        padding-left: 0;
      }
      padding-left: 25px;
      & > :first-child {
        margin-top: 0;
      }
      & > :last-child {
        margin-bottom: 0;
      }
    }
  }

  // Jedi: new = good
  &__box-header-with-creast {
    padding-right: 110px;
    min-height: 84px;
  }
  &__box-header-creast {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
  }

  &__xs-creast {
    display: inline-block;
    border-radius: 100%;
    background-color: theme-color('primary');
    height: 20px;
    width: 20px;
    line-height: 20px;
    color: gray('white');
    text-align: center;
    font-weight: 500; //font-weight: 400;;
    margin-right: 10px;

    &--doubledigit {
      font-size: 0.75em;
    }

    &--gray {
      background-color: gray('gray-400');
    }
  }
  &__rate {
    color: gray('gray-400');
  }
  &__ratings {
    margin-bottom: 20px;
    &-link {
      display: inline-block;
      margin-left: 10px;
    }
  }
}
</style>
