<template>
  <div class="box o-accpaybox">
    <div class="box__header">
      <h2 class="box__header-title box__header-title--green">{{ $t('accUpdateBoxTitle') }}</h2>
    </div>

    <div class="box__body">
      <p>{{ $t('accUpdateBoxText') }}</p>
      <a
        href="https://www.garantiegcr.com/zone/formulaire/Bilan-personnel-GCR-009"
        target="_blank"
        class="btn btn-primary btn-sm btn-primary--download"
        >{{ $t('accUpdateBoxBtn') }}</a
      >
    </div>
  </div>
</template>

<script>
import {} from '@/_helpers'

require('../../utilities/box')
require('../../utilities/button')

export default {
  components: {},
  data() {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';
</style>
