<template>
  <div class="box">
    <div class="box__header">
      <!-- {{ this.accStatus }} : {{ this.accRenewalStatus }} : {{ isDraft }} : {{ submitedDate}} -->
      <h2
        v-if="
          accStatus !== $Consts('ACCREDITATION_STATUS_ACTIVE') &&
            accStatus !== $Consts('ACCREDITATION_STATUS_RENEWAL_IN_PROGRESS') &&
            accStatus !== $Consts('ACCREDITATION_STATUS_RENEWAL_SUBMITTED')
        "
        class="box__header-title box__header-title--green"
      >
        {{ accreditationRenewBoxTitle('accRenewBoxTitle') }}
      </h2>
      <h2 v-else class="box__header-title box__header-title--green">{{ accreditationRenewBoxTitle('accRenewBoxTitleDone') }}</h2>
    </div>
    <div class="box__body">
      <button-gcr
        v-if="accStatus === $Consts('ACCREDITATION_STATUS_PENDING_RENEWAL') && !isDraft"
        btnClass="btn-primary btn-sm btn-arrow"
        @btnclick="accreditationFormUrlClickNew()"
        label="accRenewBoxBtnNew"
      />
      <button-gcr
        v-if="accStatus === $Consts('ACCREDITATION_STATUS_PENDING_RENEWAL') && isDraft"
        btnClass="btn-primary btn-sm btn-arrow"
        @btnclick="accreditationFormUrlClickNew()"
        label="accRenewBoxBtnEdit"
      />

      <p
        v-if="
          accStatus === $Consts('ACCREDITATION_STATUS_RENEWAL_IN_PROGRESS') ||
            accStatus === $Consts('ACCREDITATION_STATUS_RENEWAL_SUBMITTED')
        "
      >
        <strong class="text-warning">{{ $t('accDetailStatusPendingInProgress') }}</strong>
      </p>

      <p
        v-if="
          accStatus !== $Consts('ACCREDITATION_RENEWAL_STATUS_CLOSED') &&
            accStatus !== $Consts('ACCREDITATION_STATUS_PENDING_RENEWAL') &&
            accRenewBoxSubmitDate
        "
      >
        <strong>{{ $t('accRenewBoxSubmitDate') }}</strong> {{ this.accRenewBoxSubmitDate }}
        <!--<br />
        <strong>{{ $t('accRenewBoxPayDate') }}</strong> {{ this.accRenewBoxPayDate }}<br />
        <strong>{{ $t('accRenewBoxRenewDate') }}</strong> {{ this.accRenewBoxRenewDate }} -->
      </p>
      <!--
      <p
        v-if="
          accStatus !== $Consts('ACCREDITATION_STATUS_ACTIVE') &&
            accStatus !== 'CLOSED' &&
            accStatus !== $Consts('ACCREDITATION_STATUS_RENEWAL_SUBMITTED')
        "
        v-html="accreditationRenewBoxText"
      ></p>
      -->
      <p v-if="accStatus === $Consts('ACCREDITATION_STATUS_PENDING_RENEWAL')" v-html="$t('accRenewBoxHTML')"></p>

      <p
        v-if="
          accStatus === $Consts('ACCREDITATION_STATUS_RENEWAL_SUBMITTED') ||
            accStatus === $Consts('ACCREDITATION_STATUS_RENEWAL_IN_PROGRESS')
        "
      >
        {{ $t('accRenewBoxInProgress') }}
      </p>

      <p v-if="this.accreditation.renewalFormDownloadUrl">
        <a :href="getDownloadUrl(this.accreditation.renewalFormDownloadUrl)">{{ $t('accRenewBoxDownloadForm') }}</a>
      </p>

      <p
        v-if="
          (accStatus === $Consts('ACCREDITATION_STATUS_RENEWAL_SUBMITTED') ||
            accStatus === $Consts('ACCREDITATION_STATUS_ACTIVE')) &&
            this.accreditation.renewal &&
            this.accreditation.renewal.invoice &&
            this.accreditation.renewal.invoice.downloadUrl &&
            this.accreditation.renewal.invoice.balance <= 0
        "
      >
        <a :href="getDownloadUrl(this.accreditation.renewal.invoice.downloadUrl)">{{ $t('accRenewBoxInvoice') }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import { Urls, Dates, Consts } from '@/_helpers'
import ButtonGcr from '@/components/atoms/Button'

require('../../utilities/box')

export default {
  components: { ButtonGcr },
  props: {
    accRenewalStatus: String,
    accStatus: String,
    isDraft: Boolean,
    submitedDate: String,
    accreditation: Object
  },
  computed: {
    accRenewBoxSubmitDate() {
      return Dates.formatDate(this.submitedDate)
    } /*
    accRenewBoxPayDate() {
      return Dates.formatDate('2019-07-03')
    },
    accRenewBoxRenewDate() {
      return Dates.formatDate('2019-07-03')
    },*/
    /*accreditationRenewBoxText() {
      return i18n.t('accRenewBoxHTML')
      .replace('$1', '<a href="mailto:accreditation@garantiegcr.com">accreditation@garantiegcr.com</a>')
    }*/
  },
  methods: {
    accreditationFormUrlClickNew() {
      this.$router.push(`${Urls.localizedUrl(Urls.ACCREDITATION_RENEW)}`)
    },
    getDownloadUrl(relativeUrl) {
      return Consts.ROOT_API + relativeUrl
    },
    accreditationRenewBoxTitle(title) {
      let renewalDate = Dates.getYear(this.accreditation.renewalDate)

      if (this.accStatus === Consts.ACCREDITATION_STATUS_ACTIVE) {
        renewalDate = `${renewalDate - 1}-${renewalDate}`
      } else {
        renewalDate = `${renewalDate}-${renewalDate + 1}`
      }

      return this.$t(title).replace('$1', renewalDate) //TODO: Fred : anée du renouvellement actif
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';
</style>
