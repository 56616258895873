<template>
  <div class="box o-accpaybox">
    <div class="box__header">
      <h2 class="box__header-title box__header-title--green">{{ $t('accPayBoxTitle') }}</h2>
    </div>
    <div class="box__body">
      <p>{{ $t('accPayBoxIntro') }}</p>
      <p>{{ $t('accPayBoxNoticeDelay') }}</p>
      <div class="o-accpaybox__amount">
        <span class="o-accpaybox__amount-label">{{ $t('accPayBoxAmountToPay') }}</span> {{ amountToPay }}
      </div>

      <div class="o-accpaybox__grid">
        <div
          class="o-accpaybox__row"
          v-for="invoice in invoices"
          v-bind:key="invoice.id"
          v-on:click="openLink(invoice.downloadUrl)"
        >
          <div class="o-accpaybox__col">{{ invoice.number }}</div>
          <div class="o-accpaybox__col">
            <strong class="o-accpaybox__grid-amount">{{ invoice.grandTotal }}</strong>
          </div>
          <div class="o-accpaybox__col">{{ $t('paymentCode') }} {{ invoice.paymentCode }}</div>
          <div class="o-accpaybox__col">{{ invoice.days }}</div>
          <div class="o-accpaybox__col o-accpaybox__col--download">
            <button class="btn btn-download" v-tooltip="$t('download')"></button>
          </div>
        </div>
      </div>

      <blockquote>{{ $t('accPayBoxAmountNote') }}</blockquote>
      <!--
      <button-gcr btnClass="btn-primary btn-sm btn-arrow" @btnclick="projectsUrlClick()" label="accOngoingProjectBtn" />
      -->
    </div>
    <div class="box__body">
      <h3 class="o-accpaybox__subtitle">{{ $t('payProjectPayFinancialInstitution') }}</h3>
      <p>{{ $t('accPayBoxPayFinancialInstitutionNote') }}</p>
      <div class="row">
        <div class="col-6">
          <div class="o-accpaybox__logobox">
            <img class="o-accpaybox__logobox-img" src="../../assets/desjardins.png" width="180" height="48" alt="Desjardins" />
          </div>
        </div>
        <div class="col-6">
          <div class="o-accpaybox__logobox">
            <img
              class="o-accpaybox__logobox-img"
              src="../../assets/banque_nationale.png"
              width="165"
              height="53"
              alt="Banque Nationale"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="box__body">
      <h3 class="o-accpaybox__subtitle">{{ $t('accPayBoxPayByCheck') }}</h3>
      <p>{{ $t('accPayBoxPayTo') }}</p>
      <p>
        {{ $t('accPayBoxSendCheckAddress') }}<br />
        {{ $t('accPayBoxAddressLine1') }}<br />
        {{ $t('accPayBoxAddressLine2') }}
      </p>
    </div>
    <!-- https://sigmund.leankit.com/card/892074622
    <div class="box__body">
      <h3 class="o-accpaybox__subtitle">{{ $t('accPayBoxPayByCreditCardTitle') }}</h3>
      <p>{{ $t('accPayBoxPayByCreditCardText') }}</p>
    </div>
    -->
  </div>
</template>

<script>
import { Consts, Cash, Dates } from '@/_helpers'

require('../../utilities/box')
export default {
  components: {},
  props: { accreditation: Object },
  data() {
    return {
      amountToPay: Cash.format(this.accreditation.renewal.invoice.balance),
      invoices: [
        {
          id: this.accreditation.renewal.invoice.id,
          balance: Cash.format(this.accreditation.renewal.invoice.balance),
          grandTotal: Cash.format(this.accreditation.renewal.invoice.grandTotal),
          number: this.accreditation.renewal.invoice.number,
          downloadUrl: `${Consts.ROOT_API}${this.accreditation.renewal.invoice.downloadUrl}`,
          days: Dates.formatDays(Dates.diffDays(this.accreditation.renewal.invoice.issueDate, new Date())),
          paymentCode: this.accreditation.renewal.invoice.paymentCode
        }
      ]
    }
  },
  methods: {
    openLink: function(link) {
      if (link.startsWith('/')) {
        this.$router.push(link)
      } else {
        //window.location.href = link
        window.open(link)
      }
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';
.o-accpaybox {
  &__amount {
    font-size: 30px;
    font-weight: 500;
    color: gray('gray-400');
    line-height: 26px;
    margin-bottom: 24px;
    &-label {
      display: block;
      font-size: 17px;
      color: gray('gray-700');
    }
  }

  &__grid {
    margin-bottom: 30px;
    border: 1px solid gray('gray-150');
    border-bottom: 0;
    &-amount {
      font-size: 16px;
    }
  }
  &__row {
    @media screen and (min-width: breakpoint-min('md')) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem 0;
    }
    border-bottom: 1px solid gray('gray-150');
    cursor: pointer;
    position: relative;
    padding: 0.75rem 60px 0.75rem 0;
  }
  &__col {
    padding: 0 0.75rem;
    font-size: 14px;

    &--download {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      @media screen and (min-width: breakpoint-min('md')) {
        position: relative;
        transform: translateY(0%);
      }
    }
  }
  &__subtitle {
    font-size: 17px;
    color: gray('gray-700');
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  &__logobox {
    border: 1px solid gray('gray-150');
    background-color: gray('white');
    padding: 15px;
    text-align: center;
    @media screen and (min-width: breakpoint-min('md')) {
      min-height: 120px;
    }
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>
