<template>
  <div class="box">
    <div class="box__header">
      <h2 class="box__header-title box__header-title--green">{{ $t('accOngoingProjectTitle') }}</h2>
    </div>
    <div class="box__body">
      <p>{{ $t('accOngoingProjectText') }}</p>
      <button-gcr btnClass="btn-primary btn-sm btn-arrow" @btnclick="projectsUrlClick()" label="accOngoingProjectBtn" />
    </div>
  </div>
</template>

<script>
import { Urls, Consts } from '@/_helpers'
import ButtonGcr from '@/components/atoms/Button'

require('../../utilities/box')

export default {
  components: { ButtonGcr },
  data() {
    return {}
  },
  methods: {
    projectsUrlClick() {
      this.$router.push(`${Urls.localizedUrl(Urls.PROJECTS)}?ct=${Consts.ONGOING_PROJECTS}`)
    },
    contractUrl() {
      return filePath => {
        return `${Consts.ROOT_API}${filePath}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';
</style>
