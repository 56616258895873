<template>
  <div class="box" v-if="accreditation.submittedDocuments">
    <div class="box__header">
      <h2 class="box__header-title box__header-title--green">{{ $t('documentsHistory') }}</h2>
    </div>
    <div class="box__body">
      <div class="o-accdoc">
        <div class="o-accdoc__grid">
          <div v-for="item in accreditation.submittedDocuments" :key="item.id" class="o-accdoc__row">
            <div class="o-accdoc__col">
              <strong class="o-accdoc__grid__title">{{ item.fileName }}</strong> {{ docTypeText(item.documentType) }}
            </div>
            <div class="o-accdoc__col">{{ item.uploadedAt }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Consts } from '@/_helpers'
require('../../utilities/button')
require('../../utilities/box')
export default {
  props: {
    accreditation: Object,
    docTypeOptions: Array
  },
  methods: {
    openLink: function(link) {
      window.open(Consts.ROOT_API + link)
    }
  },
  computed: {
    docTypeText() {
      return value => {
        const match = this.docTypeOptions.filter(obj => obj.value === value)
        return match && match[0] ? this.$t(match[0].text) : ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';

.o-accdoc {
  //margin: 15px 0;
  &__grid {
    //margin-bottom: 30px;
    border: 1px solid gray('gray-150');
    border-bottom: 0;
    &__title {
      font-size: 16px;
      display: block;
    }
  }
  &__row {
    @media screen and (min-width: breakpoint-min('md')) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem 0;
    }
    border-bottom: 1px solid gray('gray-150');
    //cursor: pointer;
    position: relative;
    padding: 0.75rem 60px 0.75rem 0;
    background-color: gray('white');
  }
  &__col {
    padding: 0 0.75rem;
    font-size: 14px;
    min-width: 25%;

    &--download {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      @media screen and (min-width: breakpoint-min('md')) {
        position: relative;
        transform: translateY(0%);
      }
    }
  }
}
</style>
