<template>
  <div class="page">
    <div class="page__header row justify-content-center">
      <div class="col-12 col-xs-11 page__header__content">
        <h1 class="page__header__title">{{ $t('navSideAccreditations') }}</h1>
      </div>
    </div>
    <accreditation-detail v-if="accreditation.status" :accreditation="accreditation"></accreditation-detail>
    <div class="page__header row justify-content-center" v-if="!isLoading">
      <div class="col col-xs-11 page__header__tab page__header__tab--overlap">
        <div
          class="page__header__tab__item"
          v-on:click="setTab(stepsTab)"
          :class="{ 'page__header__tab__item--active': currentTab == stepsTab }"
        >
          {{ $t('steps') }}
        </div>
        <div
          class="page__header__tab__item"
          v-on:click="setTab(documentsTab)"
          :class="{ 'page__header__tab__item--active': currentTab == documentsTab }"
        >
          {{ $t('documents') }}

          <badge
            v-if="getTotalNumberOfDocuments"
            :content="getTotalNumberOfDocuments"
            :badgeClass="[{ 'badge-white': currentTab != documentsTab }, { 'badge-gray': currentTab == documentsTab }]"
          />
        </div>
      </div>
    </div>

    <div class="page__body page__body--minheight row justify-content-center">
      <div class="col-12 col-xs-11 page__body__col">
        <loading-wrapper :isLoading="isLoading">
          <div class="row project-row-offset">
            <div class="col-12 col-md-4 order-md-1">
              <!-- right col -->
              <accreditation-missing-document-box
                v-if="accreditation.missingDocuments && accreditation.missingDocuments.length != 0"
                :accreditation="accreditation"
              />
              <accreditation-upload-document-box
                @uploadDone="refreshDocument"
                :docTypeOptions="docTypeOptions"
                v-if="showRenewalBox"
              />
            </div>
            <div class="col-12 col-md-8" v-show="currentTab == stepsTab">
              <!-- My steps Tab -->

              <accreditation-renew-box
                :submitedDate="this.accreditation.renewalSubmittedDate"
                :accRenewalStatus="accRenewalStatus"
                :accStatus="accStatus"
                :accreditation="this.accreditation"
                :isDraft="this.accreditation.drafts != null && this.accreditation.drafts.renewalForm != null"
                v-if="showRenewalBox"
              />
              <accreditation-pay-box v-if="showPayBox" :accreditation="accreditation" />
              <accreditation-update-box :accreditation="accreditation" />
              <accreditation-ongoing-project-box />
            </div>
            <div class="col-12 col-md-8" v-show="currentTab == documentsTab">
              <documents-box
                title="documentsHistoryGCR"
                :documentsTypes="docTypeOptions"
                :documents="allDocumentsGCR"
                :paging="docPerPage"
              />

              <documents-box
                title="documentsHistory"
                :documentsTypes="docTypeOptions"
                :documents="allDocuments"
                :paging="docPerPage"
              />
            </div>
          </div>
        </loading-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import AccreditationDetail from '@/components/organism/AccreditationDetail'
import AccreditationDocument from '@/components/organism/AccreditationDocument'
import DocumentsBox from '@/components/organism/DocumentsBox'
import AccreditationMissingDocumentBox from '@/components/organism/AccreditationMissingDocBox'
import AccreditationUploadDocumentBox from '@/components/organism/AccreditationUploadDocBox'
import AccreditationOngoingProjectBox from '@/components/organism/AccreditationOngoingProjectBox'
import AccreditationRenewBox from '@/components/organism/AccreditationRenewBox'
import AccreditationPayBox from '@/components/organism/AccreditationPayBox'
import AccreditationUpdateBox from '@/components/organism/AccreditationUpdateBox'
import LoadingWrapper from '@/components/organism/LoadingWrapper'
import Badge from '@/components/atoms/Badge'
import { Consts, Dates } from '@/_helpers'
import getAccreditationDetails from '@/graphql/accreditations/getAccreditationDetails.graphql'

require('../utilities/page')

export default {
  components: {
    AccreditationDetail,
    AccreditationMissingDocumentBox,
    AccreditationUploadDocumentBox,
    AccreditationOngoingProjectBox,
    AccreditationRenewBox,
    AccreditationPayBox,
    AccreditationUpdateBox,
    LoadingWrapper,
    AccreditationDocument,
    DocumentsBox,
    Badge
  },
  data() {
    var currentTab = 'steps'
    if (this.$route.query.currentTab) {
      currentTab = this.$route.query.currentTab
    }
    return {
      accreditation: {},
      //isLoading: true,
      isLoading: false,
      currentTab,
      stepsTab: 'steps',
      documentsTab: 'documents',
      //accState: '',
      accRenewalStatus: '',
      accStatus: '',
      allDocuments: [],
      allDocumentsGCR: [],
      docPerPage: 5,
      docTypeOptions: [
        { value: 'financialStatement', submitValue: 'FINANCIAL_STATEMENT', text: 'accUploadDocTypeFinancialStatement' },
        {
          value: 'financialStatementOfGuarantee',
          submitValue: 'FINANCIAL_STATEMENT_OF_GUARANTEE',
          text: 'accUploadDocTypeFinancialStatementOfGuarantee'
        },
        { value: 'T2125', submitValue: 'T2125', text: 'accUploadDocTypeT2125' },
        { value: 'personalBalanceSheet', submitValue: 'PERSONAL_BALANCE_SHEET', text: 'accUploadDocTypePersonalBalanceSheet' },
        { value: 'taxStatement', submitValue: 'TAX_STATEMENT', text: 'accUploadDocTypeTaxStatement' },
        { value: 'mortgageStatement', submitValue: 'MORTGAGE_STATEMENT', text: 'accUploadDocTypeMortgageStatement' },
        { value: 'investmentStatement', submitValue: 'INVESTMENT_STATEMENT', text: 'accUploadDocTypeInvestmentStatement' },
        { value: 'bankStatement', submitValue: 'BANK_STATEMENT', text: 'accUploadDocTypeBankStatement' },
        { value: 'CV', submitValue: 'CV', text: 'accUploadDocTypeCV' },
        { value: 'charteredEvaluation', submitValue: 'CHARTERED_EVALUATION', text: 'accUploadDocTypeCharteredEvaluation' },
        {
          value: 'bankruptcyReleaseCertificate',
          submitValue: 'BANKRUPTCY_RELEASE_CERTIFICATE',
          text: 'accUploadDocTypeBankruptcyReleaseCertificate'
        },
        { value: 'identityDocument', submitValue: 'IDENTITY_DOCUMENT', text: 'accUploadDocTypeIdentityDocument' },
        { value: 'boardResolution', submitValue: 'BOARD_RESOLUTION', text: 'accUploadDocTypeBoardResolution' },
        { value: 'other', submitValue: 'OTHER', text: 'accUploadDocTypeOther' }
      ]
    }
  },
  methods: {
    setTab(tab) {
      this.currentTab = tab
    },
    refreshDocument() {
      this.$apollo.queries.getcurrentaccreditation.refresh()
    },
    initDocs() {
      this.allDocuments = this.accreditation.submittedDocuments.map(doc => ({
        id: doc.id,
        name: doc.fileName,
        date: doc.uploadedAt,
        type: doc.documentType,
        page: this.docPerPage
      }))

      this.allDocumentsGCR = this.accreditation.gcrDocuments
        .map(doc => ({
          id: doc.id,
          link: `${Consts.ROOT_API}${doc.downloadUrl}`,
          name: doc.fileName,
          date: doc.uploadedAt,
          type: doc.documentType,
          page: this.docPerPage
        }))
        .sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })
    }
  },
  computed: {
    showRenewalBox() {
      return this.accreditation.status != Consts.ACCREDITATION_STATUS_CLOSED
    },
    showUploadBox() {
      return this.accreditation.status != Consts.ACCREDITATION_STATUS_CLOSED
    },
    showPayBox() {
      return this.accreditation.renewal && this.accreditation.renewal.invoice && this.accreditation.renewal.invoice.balance > 0
    },
    getTotalNumberOfDocuments() {
      if (this.accreditation.submittedDocuments && this.accreditation.gcrDocuments) {
        return this.accreditation.submittedDocuments.length + this.accreditation.gcrDocuments.length
      } else {
        return 0
      }
    }
  },
  apollo: {
    getcurrentaccreditation: {
      query: getAccreditationDetails,
      variables() {
        return {
          id: this.$store.getters[Consts.GETTER_ACCREDITATION_CURRENT_ID]
        }
      },
      update(data) {
        this.accreditation = data.accreditation
        this.initDocs()

        if (this.accreditation.renewal) {
          this.accRenewalStatus = this.accreditation.renewal.status
        }
        this.accStatus = this.accreditation.status
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../utilities/config';

.page__header__title {
  &::after {
    background-image: url(../assets/accreditation_gray.svg);
  }
}
</style>
